import React from "react";
import Navbar from "./Navbar";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import Countdown from "react-countdown";
import { useState } from "react";
import moment from "moment";
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
  SystemProgram,
} from "@solana/web3.js";
import { BrowserView, isMobile } from "react-device-detect";
//const splToken = require('@solana/spl-token');

const NETWORK = clusterApiUrl("mainnet-beta");
// const express = require('express');
// var cors = require('cors');
// var app = express();

// app.use(cors());

function HeroSection() {
  // const [mint, setMint] = useState(false);
  const [showMint, setShowMint] = useState(false);
  //const [provider, setProvider] = any;

  //  const displayMint = () => {
  //     return (
  //      <button>TEStse</button>
  //     )
  //  }

  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
    // window.open("https://phantom.app/", "_blank");
  };

  //const provider = getProvider();
  const [logs, setLogs] = useState([""]);
  const addLog = (log: string) => setLogs([...logs, log]);
  const connection = new Connection(NETWORK);

  const createTransferTransaction = async () => {
    if (!getProvider().publicKey) {
      return;
    }
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: getProvider().publicKey,
        toPubkey: "8xstjBH1ShxExNYCTpKcEbNAYmpCVcyCrR4cZHeJzQaW", //provider.publicKey,
        //1 lamport is 0.000000001 sol, 1000000 lamports is 0.16 cents
        lamports: 1500000000,
      })
    );
    transaction.feePayer = getProvider().publicKey;
    addLog("Getting recent blockhash");
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  };

  const requestTransaction = () => {
    if (window.solana.isConnected) {
      sendTransaction();
    } else {
      //alert("Connect Wallet First!")
      //If provider is phantom, log them in to wallet, or send them to phantom website if they don't have an account.
      if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
          //Connects to Phantom wallet.
          window.solana.connect();
          //window.solana.connect({ onlyIfTrusted: true });
          window.solana.on("connect", () => sendTransaction());
        } else {
          window.open("https://phantom.app/", "_blank");
        }
      }
    }
  };

  const sendTransaction = async () => {
    const transaction = await createTransferTransaction();
    if (transaction) {
      try {
        let signed = await getProvider().signTransaction(transaction);
        // addLog("Got signature, submitting transaction");
        let signature = await connection.sendRawTransaction(signed.serialize());
        // addLog(
        //   "Submitted transaction " + signature + ", awaiting confirmation"
        // );
        await connection.confirmTransaction(signature);
        // addLog("Transaction " + signature + " confirmed");
      } catch (err) {
        console.warn(err);
        alert("Transaction Failed.")
        // addLog("Error: " + JSON.stringify(err));
      }
    }
  };

  // // Create new token mint
  // const mint = async() => splToken.Token.createMint(
  //   connection,
  //   fromWallet,
  //   fromWallet.publicKey,
  //   null,
  //   9,
  //   splToken.TOKEN_PROGRAM_ID,
  // );

  return (
    // <div className="hero-container">
    <div className="container">
      <img className="bg" src="images/hero_temp_v2.png" />
      <div className="top-right">
        {/* {(window.innerWidth > 900) ?
      (<Navbar/>) : ('YEET')
} */}
        <Navbar />
      </div>
      <div className="below_center">
        {/* <div className="hero-container"  style={{background: "url('images/hero_backgroundv2.png') center center/cover"}}> */}
        {/* <img src="images/hero_background.png"/> */}
        {/* <img style={{'z-index': "-1"}} src="images/img-home.jpg"/> */}
        {/* <video src="/videos/moonvideo.mp4" autoPlay loop muted /> */}
        {/* <h1 className="meet">Meet The Sol Wolves!</h1>
      <br />
      <h2 className="howling">Howling in the Solana Ecosystem</h2> */}
        {/* <h2><Countdown date={"2021-09-09 19:07:00"}/>  Date.now() + 5000  */}
        {/* </h2> */}
        {/* <br />
      <br /> */}
        {/* {
        moment().isBetween(
          moment("2021-09-12 17:07:00"),
          moment("2021-09-12 22:03:20")
        ) ? ( */}
        {isMobile ? null : (
          <>
            {moment().isBetween(
              moment("2021-10-03T17:00:00Z"),
              moment("2021-10-04T20:00:00Z")
            ) ? (
              <>
                <Countdown className="countdown" date={"2021-10-04T20:00:00Z"} />
                <br />
                <button className="mint_button_disabled">MINT</button>
              </>
            ) : (
              <>
              {/* <h1 className="white">184/5555 left</h1> */}
              {/* <button
                className="mint_button"
                onClick={() => requestTransaction()}
              >
                MINT
              </button> */}
              </>
            )}
          </>
        )}
        {/* ) : (
          <button className="mint_button" onClick={() => requestTransaction()}>MINT</button>
        )
        //setShowMint(true)
        //alert("In real tidfasffme?")
      } */}
        {/* <button disabled={mint}>Mint</button> */}
        {/* { mint ? <button>Mint</button> : null } */}
      </div>
      {/* <div className="moving-nfts" style={{background: "url('images/nft_display_test.png')"}}></div> */}
      {/* <div className="bottom-left">
      <img className="scrolling_image" src="images/NFT_banner.png"/>
    </div> */}
    </div>
  );
}

export default HeroSection;
