import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [connected, setConnected] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();

    // Will either automatically connect to Phantom, or do nothing.
    //getProvider();
    window.addEventListener('load', () => {
    if ("solana" in window) {
        ///alert("Solana is in window")
        const provider = window.solana;
        if (provider.isPhantom) {
          //Connects to Phantom wallet.
          //window.solana.connect();
          window.solana.connect({ onlyIfTrusted: true });
          window.solana.on("connect", () => setConnected(true));
        } else {
          window.open("https://phantom.app/", "_blank");
        }
      }   
    })
      ///alert("Use Effect is running")
//     if (!window.solana.isConnected) {
//  window.solana.connect({ onlyIfTrusted: true });
//  window.solana.on("connect", () => setConnected(true));
//     }
  }, []);

  window.addEventListener("resize", showButton);

  //If provider is phantom, log them in to wallet, or send them to phantom website if they don't have an account.
  const getProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        //Connects to Phantom wallet.
        window.solana.connect();
        //window.solana.connect({ onlyIfTrusted: true });
        window.solana.on("connect", () => setConnected(true));
      } else {
        window.open("https://phantom.app/", "_blank");
      }
    }
  };

  //Disconnects phantom wallet from site.
  const disconnectPhantom = () => {
    window.solana.disconnect();
    window.solana.on('disconnect', () => setConnected(false));
  };

  return (
    <>
      <div className="navbar">
        <a href="https://discord.com/">
          <i className="fab fa-discord fa-2x discnav"></i>
        </a>
        <a href="https://twitter.com/">
              <i className="fab fa-twitter fa-2x twitnav"></i>
          </a>
        {connected ? (
          <button className="connect-button" onClick={() => disconnectPhantom()}>
            Disconnect
          </button>
        ) : (
          <button className="connect-button" onClick={() => getProvider()}>
            Connect Wallet
          </button>
        )}
        {/* <div className="navbar-container">
            <Link to="/" className="navbar-logo" onClick=
            {closeMobileMenu}>
              TRVL <i className="fab fa-typo3"/>
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Home
                </Link> 
              </li>
              <li className='nav-item'>
                <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                  Services
                </Link> 
              </li>
              <li className='nav-item'>
                <Link to='/products' className='nav-links' onClick={closeMobileMenu}>
                  Products
                </Link> 
              </li>
              <li className='nav-item'>
                <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                  Hidden Button?
                </Link> 
              </li>
            </ul>
              {button && <Button buttonStyle='btn--outline'>Sign Up</Button>}
            <button onClick={getProvider}>Test</button>
          </div> */}
      </div>
    </>
  );
}

export default Navbar;
