import React from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Footer.css";
import InDevelopment from "./Indevelopment";

function Footer() {
  return (
    <div className="footer_container">
      <img className="footer_bg" src="images/footer_latest.png"/>
      <div className="center_right">
          <section className="social-media">
        <div className="social-media-wrap">
          <div className="social-icons">
          <Link to="/privacy" className="privacy">
              Terms and Conditions
        </Link>
            <a href="https://discord.com/">
              <i className="fab fa-discord fa-2x discfoot"></i>
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter fa-2x twitfoot"></i>
            </a>
          </div>
        </div>
      </section>
      </div>
      {/* <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className="footer-subscription-text">You can unsub at any time.</p>
        <div className="input-areas">
          <form>
            <input
              type="email"
              name="email"
              placeholder="Your email"
              className="footer-input"
            />
            <Button buttonStyle="btn--outline">Subscribe</Button>
          </form>
        </div>
      </section> */}
      {/* <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/sign-up">How it works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Investors</Link>
            <Link to="/">Terms of Services</Link>
          </div>
        </div>
      </div> */}
      {/* <section className="social-media">
        <div className="social-media-wrap">
          <div className="social-icons">
            <a href="https://discord.gg/3nvp6Muer9">
              <i className="fab fa-discord fa-3x discfoot"></i>
            </a>
            <a href="https://twitter.com/SolWolvesNFT">
              <i className="fab fa-twitter fa-3x twitfoot"></i>
            </a>
          </div>
        </div>
      </section> */}
      {/* <div className="in_development">
        <br />
        <h1>Website Still In Development For Certain Devices</h1>
        <br />
        <br />
        <div className="bottom-development">
          <img className="scrolling_image" src="images/NFT_banner.png" />
        </div>
      </div> */}
      {/* <div className="solana">
        <h1>
          Powered By &nbsp;
          <img className="solana-image" src="/images/solana_02.png" />
        </h1>
      </div> */}
    </div>
  );
}

export default Footer;
