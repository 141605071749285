import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
  return (
    <div className="roadmap">
      <h1>Roadmap</h1>
      <div className="RoadmapRow">
        <div className="RoadmapCard-Root">
          <div class="RoadmapCard-title">* Eclipse feature *</div>
          <div class="RoadmapCard-date">Late September</div>
          <div class="RoadmapCard-content">
            Every 2-4 weeks, we will buy the floor price of all Wolves; this
            will cause the value of your NFT to increase. This means that you,
            the wolf pack, can help the growth of our project by setting a floor
            price specifically coerced for the full moon!
          </div>
        </div>
        <div class="RoadmapLine">
          <div class="RoadmapLineCenter">
            <div class="RoadmapLineDot"></div>
          </div>
        </div>
        <div class="RoadmapEmpty"></div>
      </div>
      <div class="RoadmapRow RoadmapRowFlip">
        <div class="RoadmapCard-Root">
          <div class="RoadmapCard-title">* Help-a-pup initiative *</div>
          <div class="RoadmapCard-date">Early October</div>
          <div class="RoadmapCard-content">
            Every full moon, typically once a month, 10% of sales will be
            donated to animal orientated charities, whether that is a endangered
            wolf charity or your local animal rescue. Donating to charity is one
            of the core principals of our project, as we believe we can make a
            real, direct difference in the lives of hundreds of animals.
          </div>
        </div>
        <div class="RoadmapLine">
          <div class="RoadmapLineCenter">
            <div class="RoadmapLineDot"></div>
          </div>
        </div>
        <div class="RoadmapEmpty"></div>
      </div>
      {/* <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/img-home.jpg"
              text="Explore the hidden waterfall"
              label="Adventure"
              path="/services"
            />
            <CardItem
              src="images/img-home.jpg"
              text="Explore the hidden waterfall"
              label="Adventure"
              path="/services"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/img-home.jpg"
              text="Explore the hidden waterfall"
              label="Adventure"
              path="/services"
            />
            <CardItem
              src="images/img-home.jpg"
              text="Explore the hidden waterfall"
              label="Adventure"
              path="/services"
            />
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default Cards;
