import React from "react";
import CardItem from "./CardItem";
import "./Roadmap.css";

function Roadmap() {
  return (
    <div class="roadmap_container">
    <img className="roadmap_bg" src="images/roadmap1_latest.png"/>
    <img className="roadmap_bg" src="images/roadmap2_latest.png"/>
    <img className="roadmap_bg" src="images/meettheteam_latest.png"/>
    {/* <img className="roadmap_bg" src="images/grassfade_bg_v1.png"/>
    <div class="outer_outerbox">
      <div class="outer_box">
        <h1 class="roadmap_title">Roadmap</h1>
        <ul class="boxes">
          <li>
            <h3>Pre-Mint Launch - TBD</h3>
            <p class="writing">
            Only 75 Sol Wolves will be up for grabs on our pre-mint launch. Join our discord for details.
            </p>
            <div class="connector"></div>
          </li>
          <li>
            <h3>Release 5,555 Sol Wolves - TBD</h3>
            <p class="writing">
            All 5,555 Sol Wolves will be minted to the Wolf Pack community.
            </p>
            <div class="connector"></div>
          </li>
          <li>
            <h3>Official Marketplace Trading - TBD</h3>
            <p class="writing">
            We will be listed on Solanart, as well as other big marketplaces after launch.
            </p>
            <div class="connector"></div>
          </li>
          <li>
            <h3>Full Moon - TBD</h3>
            <p class="writing">
            Every 2 - 4 weeks, we will buy the floor price of all Sol Wolves, driving the value of our NFTs up. This means that you can help the growth of our project by coordinating a floor price in advance of the full moon event!
            </p>
            <div class="connector"></div>
          </li>
          <li>
            <h3>Wolf Pack Ecosystem - TBD</h3>
            <p class="writing">
            Your NFT will serve as a ticket to the rest of the Wolf Pack Ecosystem. This will give you access to new projects that will build upon the original Sol Wolves NFT. All holders of Sol Wolves will be airdropped their first NFT from that project.
            </p>
            <div class="connector"></div>
          </li>
          <li>
            <h3>Merchandise - TBD</h3>
            <p class="writing">
            A merchandise store will be opened. All money raised will go to the marketing wallet which is controlled by the Sol Wolves community! This means that you will control how we spend our marketing funds!
            </p>
            <div class="connector"></div>
          </li>
          <li>
            <h3>Giveaways and Competitions</h3>
            <p class="writing">
            There will be dozens of pre-launch and post-launch giveaways and competitions. Join our discord for more information!
            </p>
          </li>
        </ul>
      </div>
    </div> */}
    </div>
  );
}

export default Roadmap;
