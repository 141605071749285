import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Roadmap from '../Roadmap';
import FAQ from '../FAQ';
import InDevelopment from '../Indevelopment';

function Home () {
    return (
      <>
        {/* <InDevelopment/> */}
        <HeroSection/>
        {/* <Cards/> */}
        <Roadmap/>
        <FAQ/>
        <Footer />
      </>
    )
}

export default Home;