import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Privacy from './components/pages/Privacy';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import { parseCommandLine } from 'typescript';
import Presale from './components/pages/Presale';

function App() {
  return (
    <>
      <Router>
        {/* <div className="topnavwrap">
        <Navbar/>
        </div> */}
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/presale' exact component={Presale} />
          <Route path='/privacy' component={Privacy} />
          {/* <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
