import React from "react";
import CardItem from "./CardItem";
import "./FAQ.css";

function FAQ() {
  return (
    <div className="FAQ_container">
    <img className="FAQ_bg" src="images/faq_latest.png"/>
    {/* <div className="FAQ_bottom_middle">
      <div className="solana">
        <h1>
          Powered By &nbsp;
          <img className="solana-image" src="/images/solana_02.png" />
        </h1>
      </div>
    </div> */}
    {/* <div className="FAQ_centered">
    <div class="FAQ_outer_outerbox">
      <div class="FAQ_outerbox">
        <div class="FAQ_box">
          <div class="question_sections">
         <h2 class="faq_title">Frequently Asked Questions</h2>
            <p class="questions">
              <b>What are Sol Wolves?</b>
              <br />
              5,555 unique, collectable Wolves. The Sol Wolves project is the
              first Solana NFT project with actual use-case and unique
              tokenomics!
            </p>
            <p class="questions">
              <b>How much will a Sol Wolf cost?</b>
              <br />2 SOL, however, this is subject to change depending on the
              market condition a few days before launch.
            </p>
            <p class="questions">
              <b>When is the mint date?</b>
              <br />
              Thursday 30th September. Keep an eye on the discord for updates.
            </p>
            <p class="questions">
              <b>Where can I purchase a Sol Wolf?</b>
              <br />
              You will be able to mint new Sol Wolves here on https://solwolves.com at the time of the launch!
            </p>
          </div>
        </div>
      </div>
    </div>
    </div> */}
    </div>
  );
}

export default FAQ;
