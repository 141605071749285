import React from "react";
import Navbar from './Navbar';
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import Countdown from "react-countdown";
import { useState } from "react";
import moment from "moment";
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
  SystemProgram,
} from "@solana/web3.js";
//const splToken = require('@solana/spl-token');

const NETWORK = clusterApiUrl("mainnet-beta");

function InDevelopment() {
 

  return (
    // <div className="hero-container">
     <div className="container">
      <img className="bg" src="images/hero_backgroundv2.png"/>
      <div className="centered">
   <h1>Website in development.</h1>
    </div>
    {/* <div className="moving-nfts" style={{background: "url('images/nft_display_test.png')"}}></div> */}
    <div className="bottom-left">
      <img className="scrolling_image" src="images/NFT_banner.png"/>
    </div>
    </div>
  );
}

export default InDevelopment;