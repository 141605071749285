import React from "react";
import Navbar from "../Navbar";
// import "../App.css";
// import { Button } from "./Button";
import "../HeroSection.css";
import Countdown from "react-countdown";
import { useState } from "react";
import moment from "moment";
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
  SystemProgram,
} from "@solana/web3.js";
import { BrowserView, isMobile } from "react-device-detect";
//const splToken = require('@solana/spl-token');

const NETWORK = clusterApiUrl("mainnet-beta");

//IDs FOR EACH ONE instead of mint button.

function Presale() {
  // const [mint, setMint] = useState(false);
  const [showMint, setShowMint] = useState(false);
  const [code, setCode] = useState(
    "WHITELIST PRESALE NOW LIVE!"
  );

  // const code_list = [
  //   "#4551",
  //   "#4632",
  //   "#3928",
  //   "#5496",
  //   "#9998",
  //   "#6262",
  //   "#5160",
  //   "#9846",
  //   "#5476",
  //   "#8508",
  //   "#3273",
  //   "#3599",
  //   "#6526",
  //   "#1596",
  //   "#4083",
  //   "#0731",
  //   "#7898",
  //   "#9373",
  //   "#5292",
  //   "#6636",
  //   "#2993",
  //   "#4288",
  //   "#0823",
  //   "#4073",
  //   "#3984",
  //   "#8553",
  //   "#3313",
  //   "#3703",
  //   "#5813",
  //   "#9945",
  //   "#8299",
  //   "#8121",
  //   "#2869",
  //   "#6350",
  //   "#0619",
  //   "#5664",
  //   "#2904",
  //   "#4904",
  //   "#0088",
  //   "#5454",
  //   "#5668",
  //   "#4755",
  //   "#5932",
  //   "#7898",
  //   "#0950",
  //   "#8098",
  //   "#3273",
  //   "#7697",
  //   "#0101",
  //   "#1549",
  //   "#8874",
  //   "#0718",
  //   "#9428",
  //   "#6223",
  //   "#5958",
  //   "#9956",
  //   "#5264",
  //   "#9999",
  //   "#8745",
  //   "#3328",
  //   "#9873",
  //   "#0034",
  //   "#0731",
  //   "#3916",
  //   "#4308",
  //   "#5970",
  //   "#3746",
  //   "#4210",
  //   "#6562",
  //   "#0248",
  //   "#5239",
  //   "#0609",
  //   "#3604",
  //   "#5554",
  //   "#1912",
  //   "#3637",
  //   "#6640",
  //   "#3862",
  //   "#3855",
  //   "#0291",
  //   "#5020",
  //   "#1239",
  //   "#2076",
  //   "#8928",
  //   "#8078",
  //   "#7949",
  //   "#0001",
  //   "#5767",
  //   "#6718",
  //   "#2547",
  //   "#8250",
  //   "#1957",
  //   "#0273",
  // ];

  //const [provider, setProvider] = any;

  //  const displayMint = () => {
  //     return (
  //      <button>TEStse</button>
  //     )
  //  }

  const handleChange = () => {
    var entered_code = document.getElementById("uniqueID").value;
    if (entered_code == "") {
      setCode("PRESALE NOW LIVE FOR CHOSEN WOLF PACK MEMBERS!");
    } else {
      setCode(entered_code);
    }
    if (entered_code[0] == '#' & entered_code.length == 5) {
      setShowMint(true);
    }
  };

  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
    // window.open("https://phantom.app/", "_blank");
  };

  //const provider = getProvider();
  const [logs, setLogs] = useState([""]);
  const addLog = (log: string) => setLogs([...logs, log]);
  const connection = new Connection(NETWORK);

  const createTransferTransaction = async () => {
    if (!getProvider().publicKey) {
      return;
    }
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: getProvider().publicKey,
        toPubkey: "8xstjBH1ShxExNYCTpKcEbNAYmpCVcyCrR4cZHeJzQaW", //provider.publicKey,
        //1 lamport is 0.000000001 sol, 1000000 lamports is 0.16 cents
       lamports: 1500000000,
      })
    );
    transaction.feePayer = getProvider().publicKey;
    addLog("Getting recent blockhash");
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  };

  const requestTransaction = () => {
    if (window.solana.isConnected) {
      sendTransaction();
    } else {
      //alert("Connect Wallet First!")
      //If provider is phantom, log them in to wallet, or send them to phantom website if they don't have an account.
      if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
          //Connects to Phantom wallet.
          window.solana.connect();
          //window.solana.connect({ onlyIfTrusted: true });
          window.solana.on("connect", () => sendTransaction());
        } else {
          window.open("https://phantom.app/", "_blank");
        }
      }
    }
  };

  const sendTransaction = async () => {
    const transaction = await createTransferTransaction();
    if (transaction) {
      try {
        let signed = await getProvider().signTransaction(transaction);
        // addLog("Got signature, submitting transaction");
        let signature = await connection.sendRawTransaction(signed.serialize());
        // addLog(
        //   "Submitted transaction " + signature + ", awaiting confirmation"
        // );
        await connection.confirmTransaction(signature);
        // addLog("Transaction " + signature + " confirmed");
      } catch (err) {
        console.warn(err);
        alert("Transaction Failed.")
        // addLog("Error: " + JSON.stringify(err));
      }
    }
  };

  // // Create new token mint
  // const mint = async() => splToken.Token.createMint(
  //   connection,
  //   fromWallet,
  //   fromWallet.publicKey,
  //   null,
  //   9,
  //   splToken.TOKEN_PROGRAM_ID,
  // );
  // if (code=='4') {
  //   alert("test")
  // }

  return (
    // <div className="hero-container">
    <div className="presale_container">
      <img className="bg" src="images/hero_temp_v2.png" />
      <div className="top-right">
        {/* {(window.innerWidth > 900) ?
      (<Navbar/>) : ('YEET')
} */}
        <Navbar />
      </div>
      <div className="below_center">
        {/* <div className="hero-container"  style={{background: "url('images/hero_backgroundv2.png') center center/cover"}}> */}
        {/* <img src="images/hero_background.png"/> */}
        {/* <img style={{'z-index': "-1"}} src="images/img-home.jpg"/> */}
        {/* <video src="/videos/moonvideo.mp4" autoPlay loop muted /> */}
        {/* <h1 className="meet">Meet The Sol Wolves!</h1>
      <br />
      <h2 className="howling">Howling in the Solana Ecosystem</h2> */}
        {/* <h2><Countdown date={"2021-09-09 19:07:00"}/>  Date.now() + 5000  */}
        {/* </h2> */}
        {/* <br />
      <br /> */}
        {/* {
        moment().isBetween(
          moment("2021-09-12 17:07:00"),
          moment("2021-09-12 22:03:20")
        ) ? ( */}
        <h1 className="presale_title">{code}</h1>
        <br />
        {showMint ? ( null
          // <button className="mint_button" onClick={() => requestTransaction()}>
          //   MINT
          // </button>
        ) : (
          <form>
            <p className="presale_title">Enter your presale code:</p>
            <input type="text" id="uniqueID" onChange={() => handleChange()} />
            {/* <input type="submit" value="Submit"></input> */}
          </form>
        )}
      </div>
    </div>
  );
}

export default Presale;
