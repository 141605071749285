import React from "react";
import "../../App.css";

function Privacy() {
  return (
    <>
      <h1>Terms and Conditions</h1>
      <br></br>
      <h2>
        This non-fungible token collection is intended for individuals over the
        age of 18 or the legal age of your jurisdiction; whichever is greater.
        The Sol Wolves are a digital collection of Solana NFT tokens running on
        the Solana network. This website is an interface allowing participants
        to purchase digital collectibles. Users are entirely responsible for the
        safety and management of their own private Solana wallets. Users must
        validate all transactions and contracts generated by this website before
        approval. There is no ability to undo, reverse, or restore any
        transactions. This website and its connected services are provided “as
        is” and “as available” without a warranty of any kind. By using this
        website you are accepting sole responsibility for any and all
        transactions involving our collectibles. The owners of the individual
        Sol Wolves have exclusive personal and commercial rights attached to
        their non-fungible tokens. As long as you own the non-fungible token,
        you can assign these rights and do whatever you please with them. You
        agree not to use your Sol Wolf in any project or derivative work that
        involves hate speech, racism, pornography, or any other illegal content.
        Non-fungible tokens are not an investment. The Sol Wolves are just
        collectibles on the Solana network and should be treated as such. While
        we hope to continue our project, grow this community, and add utility;
        We do not guarantee future developments. You, the owner, agree that you
        purchase our non-fungible token as just as a collectible. We do not
        promise or guarantee that this non-fungible token will be worth anything
        in the future. You, the owner, understand that they have no inherent
        monetary value, and they should be treated as nothing more than a
        collectible. By minting a Sol Wolf on our website; you acknowledge that
        you have read, and do hereby accept the terms and conditions listed
        above.
      </h2>
    </>
  );
}

export default Privacy;
